import styled from "styled-components"

export const ProfilPageStyles = styled.div`
  .ProfilPageContainer {
    margin-top: 4%;
    text-align: center;
  }
  .fotoprofil {
    margin-bottom: 10px;
    width: 175px;
    height: 100%;
    border-radius: 50%;
  }
  .NamaProfil {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .PNamaProfile {
    font-weight: bolder;
    font-size: 30px;
  }
  .verifyProfil {
    margin-left: 10px;
    width: 20px;
    height: 100%;
  }

  .detailJobProfil {
    text-align: center;
    max-width: 42rem;
    padding: 0 1rem;
    margin: auto;
  }
`

export const SosmedContainer = styled.div`
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 2rem;

  icon {
    color: red;
  }
  .icon:hover {
    color: #efa51d;
  }
`
