import axios from "axios"
import { apiURLV2 } from "./utils"

export const getProfile = id => axios.get(`${apiURLV2}/user/${id}/?format=json`)

export const getWithdrawals = () =>
  axios.get(`${apiURLV2}/withdrawal/?format=json`)

export const requestWithdrawal = data =>
  axios.post(`${apiURLV2}/withdrawal/`, data)

export const requestResetPassword = data =>
  axios.post(`${apiURLV2}/dj-rest-auth/password/reset/`, data)

export const confirmResetPassword = data =>
  axios.post(`${apiURLV2}/dj-rest-auth/password/reset/confirm/`, data)
