import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Profilpage from "../components/LandingPage/ProfilPage"
import AllCourses from "../components/AllCourses"

import { getCoursesByTutor } from "../services/course"
import { getProfile } from "../services/user"
import { getParameterByName } from "../services/utils"

const ProfilPage = () => {
  const [id, setId] = useState(-1)
  const [courses, setCourses] = useState([])
  const [tutor, setTutor] = useState({})

  useEffect(() => {
    setId(getParameterByName("id"))
  }, [])

  useEffect(() => {
    if (id >= 0) {
      getCoursesByTutor(id).then(resp => {
        const data = resp.data?.results
        setCourses(data)
      })

      getProfile(id).then(resp => {
        const data = resp.data
        data ? setTutor(data) : setTutor({})
      })
    }
  }, [id])

  return (
    <Layout>
      <SEO title="Profil Tutor" />
      <Profilpage tutor={tutor} />
      <hr />
      {courses ? (
        <AllCourses courses={courses} />
      ) : (
        <h5 style={{ textAlign: "center" }}>Loading...</h5>
      )}
    </Layout>
  )
}

export default ProfilPage
