import React, { useState, useEffect } from "react"
import { ProfilPageStyles, SosmedContainer } from "./style"
import verify from "../../../images/profile_page/icon-verified.png"
import fotoTemp from "../../../images/profile_page/icon-akun.png"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe"
import { FaDribbble } from "@react-icons/all-files/fa/FaDribbble"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"

export default function Profilepage({ tutor }) {
  const [photoUrl, setPhotoUrl] = useState(fotoTemp)

  useEffect(() => {
    if (tutor?.user?.profile_picture) {
      setPhotoUrl(tutor?.user?.profile_picture)
    }
  }, [tutor])

  return (
    <ProfilPageStyles>
      <div className="ProfilPageContainer">
        <img className="fotoprofil" src={photoUrl} alt="" />
        <div className="NamaProfil">
          <p className="PNamaProfile">
            {tutor?.user?.display_name || tutor?.user?.username}
          </p>
          <img className="verifyProfil" src={verify} alt="" />
        </div>

        <p className="detailJobProfil">{tutor?.bio}</p>

        <SosmedContainer>
          {tutor?.instagram && (
            <a
              href={tutor?.instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaInstagram color="#8a3ab9" />
            </a>
          )}
          {tutor?.youtube && (
            <a
              href={`${tutor?.youtube}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaYoutube color="red" />
            </a>
          )}
          {tutor?.linkedin && (
            <a
              href={`${tutor?.linkedin}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaLinkedin color="#0077b5" />
            </a>
          )}
          {tutor?.website && (
            <a
              href={`${tutor?.website}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaGlobe color="#4e6b6a" />
            </a>
          )}
          {tutor?.dribbble && (
            <a
              href={`${tutor?.dribble}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaDribbble />
            </a>
          )}
          {tutor?.github && (
            <a
              href={`${tutor?.github}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaGithub color="#c9510c" />
            </a>
          )}
          {tutor?.twitter && (
            <a
              href={`${tutor?.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaTwitter color=" #00acee" />
            </a>
          )}
          {tutor?.facebook && (
            <a
              href={`${tutor?.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaFacebook color="#4267B2" />
            </a>
          )}
        </SosmedContainer>
      </div>
    </ProfilPageStyles>
  )
}
